var owl = $('.nav-slider');
var owlOptions = {
    items: 4,
	dots: false,
	nav: true,
	smartSpeed: 300,
	autoWidth:true,
	margin: 18,
	responsive : {
	    992 : {
	        margin: 14, 
	    },
	    1200 : {
	        margin: 24,
	    },
	}
};

var secondoryMenuSet = function(){

	if ($(window).width() >= 768) {
        owl.owlCarousel(owlOptions);
        owl.removeClass('off');
    } else {
	    owl.addClass('off').trigger('destroy.owl.carousel');
	    owl.find('.owl-stage-outer').children(':eq(0)').unwrap();
    }

};


$(document).ready(function(){

	$('.same-height-row').matchHeight();
    $('.same-height').matchHeight({byRow: false});

    $('.search-close').on('click', function(){
    	$('.wr-search').fadeOut(500); 
    });
    $('.search-open').on('click', function(e){
        e.preventDefault();
        $('.wr-search').fadeIn(500); 
    });

    secondoryMenuSet();

    $(window).on('load resize', function(){
		secondoryMenuSet();	
	});

});


// hide #back-top first
$("#back-top").hide(); 

// fade in #back-top
$(function () {
    $(window).scroll(function () {
      	if ($(this).scrollTop() > 100) {
        	$('#back-top').fadeIn();
      	} else {
        	$('#back-top').fadeOut();
      	}
    });
    // scroll body to 0px on click
    $('#back-top a').click(function () {
      	$('body,html').animate({
        	scrollTop: 0
      	}, 800);
      	return false; 
    });
});
